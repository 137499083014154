<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="customerInvoiceDialog"
      :max-width="dialogWidth"
      content-class="customer-invoice-dialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">
            Select an Invoice
          </h4>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="btx_loading"
            color="green"
            depressed
            class="custom-bold-button text-white mr-4"
            v-on:click="paynow()"
            >PAY</v-btn
          >
          <v-btn
            icon
            dark
            color="cyan"
            v-on:click="
              $emit('close', true);
              $emit('reset-all', true);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which invoice would you like to create this for?
              </h5>
              <table width="100%">
                <tr>
                  <td>
                    <v-text-field
                      ref="search-customer-invoice"
                      v-model.trim="customerInvoice"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                </tr>
              </table>

              <div class="pt-4">
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <v-list class="mx-2 mx-md-4" subheader three-line>
                    <v-skeleton-loader
                      v-if="searchLoader"
                      class="custom-skeleton"
                      type="list-item-two-line"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <template v-if="customerInvoiceList.length > 0">
                        <v-list-item
                          v-for="(data, index) in customerInvoiceList"
                          class="py-2"
                          :class="{
                            'custom-border-bottom':
                              customerInvoiceList[index + 1],
                          }"
                          :key="index"
                          link
                        >
                          <v-list-item-icon class="mr-3">
                            <v-checkbox
                              v-model="data.lxp_status"
                              color="cyan"
                              class="py-0 px-0 mx-0 py-0"
                              large
                            />
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-layout style="max-width: 150px">
                              <v-flex>
                                <Barcode
                                  class="my-0 mt-0 mb-0 mr-1"
                                  :barcode="data.barcode"
                                ></Barcode>
                              </v-flex>
                              <v-flex>
                                <CustomStatus
                                  small
                                  customer-invoice
                                  :status="data.status"
                                  endpoint="invoice/status"
                                ></CustomStatus>
                              </v-flex>
                            </v-layout>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                            >
                              {{ data.title }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="margin-auto">
                            <v-list-item-action-text
                              class="font-weight-500 mb-2 font-size-13"
                            >
                              Invoice Amount:
                              <span class="font-weight-600">{{
                                formatMoney(data.total)
                              }}</span>
                            </v-list-item-action-text>
                            <v-list-item-action-text
                              class="font-weight-500 mb-2 font-size-13"
                            >
                              Paid Amount:
                              <span class="font-weight-600 green--text">{{
                                formatMoney(data.paid_amount)
                              }}</span>
                            </v-list-item-action-text>
                            <v-list-item-action-text
                              class="font-weight-500 mb-2 font-size-13"
                            >
                              Unpaid Amount:
                              <span class="font-weight-600 red--text">{{
                                formatMoney(data.total - data.paid_amount)
                              }}</span>
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >No Invoice(s) Found
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

export default {
  mixins: [CustomerMixin, CommonMixin],
  data() {
    return {
      formValid: true,
      btx_loading: false,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      customerInvoice: null,
      timeoutLimit: 500,
      timeout: null,
      invoiceAddress: new Object(),
      createInvoiceDialog: false,
      customerInvoiceList: new Array(),
    };
  },
  watch: {
    customerInvoice(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchCustomerInvoice();
        }, _this.timeoutLimit);
      }
    },
    customer() {
      this.searchCustomerInvoice(null);
    },
  },
  props: {
    disabledAutoSelect: {
      type: Boolean,
      default: false,
    },
    customerInvoiceDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  components: {
    Barcode,
    CustomStatus,
  },
  methods: {
    paynow() {
      let selected = this.lodash.map(
        this.lodash.filter(this.customerInvoiceList, { lxp_status: true }),
        "id"
      );
      if (selected.length) {
        this.btx_loading = true;
        this.$emit("select-customer-invoice", selected);
      }
    },
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchCustomerInvoice();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchCustomerInvoice();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.customerInvoice = null;
        _this.isFocused = false;
      });
    },
    searchCustomerInvoice() {
      const _this = this;
      _this.searchLoader = true;
      _this
        .getPaymentInvoices(_this.customer, _this.customerInvoice)
        .then((response) => {
          _this.customerInvoiceList = response;
          if (
            !_this.disabledAutoSelect &&
            _this.customerInvoiceList.length === 1
          ) {
            _this.$emit(
              "select-customer-invoice",
              _this.customerInvoiceList[0].id
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.createInvoiceDialog = false;
          _this.searchLoader = false;
        });
    },
    selectCustomerInvoice(param) {
      this.$emit("select-customer-invoice", param.id);
    },
    updateInvoice(param) {
      this.invoiceAddress = param;
    },
  },
  mounted() {
    this.searchCustomerInvoice();
    this.$refs["search-customer-invoice"].focus();
  },
  computed: {
    isSearching() {
      return this.customerInvoice && this.customerInvoice.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Invoice...";
    },
  },
};
</script>
